<template>
  <div
    class="sort-style"
    :style="{
      backgroundImage: `url(${$http.imgurl + info.background_image})`,
    }"
  >
    <div class="box1 center-center">
      <van-icon name="arrow-left" size="20" @click="goback" />
    </div>
    <div class="box2">
      <div class="row1">{{ language == 1 ? info.title : info.etitle }}</div>
      <div class="row2">
        {{ language == 1 ? info.describe : info.edescribe }}
      </div>
    </div>
    <div class="box4 start-center">
      <div class="item" :class="active == 1 ? 'ac' : 'no'" @click="changes(1)">
        {{$t('text.t293')}}
      </div>
      <div class="item" :class="active == 2 ? 'ac' : 'no'" @click="changes(2)">
        {{$t('text.t53')}}
      </div>
    </div>
    <div class="box5" v-if="active == 1">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist"
      >
        <div class="nftlist start-center">
          <div class="items-out" v-for="item in list" :key="item.id">
            <div class="items" @click="goAhead(`/newdetail?orderno=${item.orderno}&chainType=${item.chain}`)">
              <img
                v-if="item.status == 1"
                :src="item.image"
                alt=""
                class="i1"
              />
              <img
                v-else-if="item.status == 0"
                :src="language=='1'?$concatImg('img60'):$concatImg('img61')"
                alt=""
                class="i1"
              />
              <img
                v-else-if="item.status == 2"
                :src="language=='1'?$concatImg('img62'):$concatImg('img63')"
                alt=""
                class="i1"
              />
              <div class="b1 between-center">
                <div class="t1 ellipsis">{{item.title}}</div>
              </div>
              <div class="b2">#{{item.tokenid}}</div>
              <div class="b3 start-center">
                <img :src="chain_list.find(ele=>ele.chain_type == item.chain).chain_logo" class="b3i1" />
                <div class="b3t1">{{item.price}}</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <div v-if="active == 2">
      <van-list
        v-model="loading1"
        :finished="finished1"
        :finished-text="$t('text.t39')"
        :offset="10"
        @load="getlist1"
      >
        <div class="box6 start-center">
          <div class="itembox" v-for="item in list1" :key="item.id">
            <el-card class="box-card">
              <div
                class="item between-end"
                :style="{
                  backgroundImage: `url(${item.selectedimage})`,
                }"
                @click="goAhead(`/set?id=${item.id}&isup=1&chainType=${item.chain}`)"
              >
                <img :src="item.collectionimage" class="i1" />
                <div class="t1 van-ellipsis">{{ item.collectionname }}</div>
              </div>
            </el-card>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      active: 1,
      info: {},
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,

      page1: 1,
      loading1: false,
      finished1: false,
      list1: [],
    };
  },
  created() {
    this.info = JSON.parse(this.$route.query.info);
  },
  computed: {
    ...mapState(["language","chain_list"]),
  },
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        classification_id: this.info.id,
      };
      this.$http.get("/core/compilation", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
    getlist1() {
      let arg = {
        page: this.page1,
        limit: this.limit,
        classification_id: this.info.id,
      };
      this.$http.get("/core/collections", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished1 = true;
        this.page1++;
        this.loading1 = false;
        this.list1 = [...this.list1, ...data];
      });
    },
    goback() {
      this.$router.back();
    },
    changes(e) {
      this.active = e;
    },
  },
};
</script>

<style lang="less">
.sort-style {
  background-position: center top;
  background-size: 100% 207px;
  background-repeat: no-repeat;
  position: relative;
  min-height: 100vh;
  .box1 {
    position: fixed;
    top: 30px;
    left: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    z-index: 999;
  }
  .box2 {
    padding: 210px 25px 19px;
    .row1 {
      font-size: 24px;
      font-weight: bold;
      color: #323232;
      margin-bottom: 10px;
    }
    .row2 {
      font-size: 12px;
      font-weight: 500;
      color: #909090;
    }
  }
  .box4 {
    padding: 0 28px;
    border-bottom: 1px solid #e1e1e1;
    .item {
      padding: 0 0 15px 0;
      margin-right: 20px;
      font-size: 12px;
      font-weight: bold;
    }
    .ac {
      color: #323232;
      border-bottom: 1px solid #323232;
    }
    .no {
      color: #909090;
    }
  }
  .box5 {
    padding-top: 15px;
    .nftlist {
      flex-wrap: wrap;
      .items-out {
        padding: 0 18px 25px;
        .items {
          width: 150px;
          .i1 {
            width: 100%;
            height: 150px;
            border-radius: 3px;
            margin-bottom: 5px;
          }
          .b1 {
            padding: 0 16px 9px 0;
            .t1 {
              flex: 1;
              font-size: 12px;
              font-weight: 500;
              color: #909090;
            }
            .b1i1 {
              width: 10px;
              height: 10px;
              margin-left: 10px;
            }
          }
          .b2 {
            font-size: 16px;
            font-weight: bold;
            color: #323232;
            margin-bottom: 9px;
          }
          .b3 {
            .b3i1 {
              width: 15px;
              height: 15px;
              margin-right: 4px;
            }
            .b3t1 {
              font-size: 12px;
              font-weight: 500;
              color: #323232;
            }
          }
        }
      }
    }
  }
  .box6 {
    padding: 21px 0 10px;
    flex-wrap: wrap;
    .itembox {
      padding: 0 17px 20px;
      .box-card {
        width: 150px;
      }
      .el-card__body,
      .el-main {
        padding: 0;
      }
      .item {
        box-sizing: border-box;
        width: 150px;
        border-radius: 10px;
        padding: 75px 10px 15px;
        background-size: 100% 100px;
        background-repeat: no-repeat;
        .i1 {
          width: 45px;
          height: 45px;
          border-radius: 3px;
          margin-right: 9px;
        }
        .t1 {
          font-size: 12px;
          font-weight: bold;
          color: #323232;
          flex: 1;
        }
      }
    }
  }
}
</style>